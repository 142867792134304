var triggerElements = document.querySelectorAll('.sub-content-items');
                triggerElements.forEach(function(triggerElement) {
                    var formContainer = triggerElement.querySelector('.container-form.--product-actions');
            
                    triggerElement.addEventListener('mouseover', function() {
                        formContainer.style.display = 'block';
                        formContainer.style.animation = 'slide-down 0.5s ease';
                    });
                    triggerElement.addEventListener('mouseout', function() {
                        formContainer.style.display = 'none';
                    });
 });